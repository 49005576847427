import { useState } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Link,
    Icon,
    SpaceBetween,
    TextContent
 } from "@cloudscape-design/components";

const StackTemplatesList = ({ templates, stack_name }) => {

    const [visible, setVisible] = useState(false);

    return (
        <>

         <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={"Templates for " + stack_name}
        >

            <SpaceBetween size='s'>
                <TextContent>
                    <p>This stack deployes the following templates:</p>

                <ul>
                {templates.map((template, key) => (
                    <li>{template['name']}</li>
                ))}
                </ul>


                </TextContent>
            </SpaceBetween>

        </Modal>

        <Link
            onFollow={() => {
                setVisible(true);
            }}
        >
            View Templates ({templates.length}) <Icon name='search' />
        </Link>
        </>

    );
};


export default connect()(StackTemplatesList);
