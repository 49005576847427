import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Container,
    Header,
    SpaceBetween,
    ColumnLayout,
    Button,
    StatusIndicator
 } from "@cloudscape-design/components";
import { useParams } from "react-router-dom";

import config from "../../config";

import {
    ValueWithLabel,
    formatTimestamp
} from "../../util";

import StackerConfigDisplay from "./StackerConfigDisplay";

const Customer = ({ token }) => {
    const [customer, setCustomer] = useState();
    let { customer_code } = useParams();
    const [state, setState] = useState({});

    const [runStackerButtonLoading, setRunStackerButtonLoading] = useState(false);
    const [runStackerButtonText, setRunStackerButtonText] = useState('Run Stacker');
    const [runStackerButtonDisabled, setRunStackerButtonDisabled] = useState(true);

    useEffect(() => {
        fetch(`${config.api_endpoint}/customers/${customer_code}`, {
            mode: "cors",
            method: "GET",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            setCustomer(json['customer'])

            switch (json['customer']['stacker_status']) {

                case 'Queued':
                    setRunStackerButtonLoading(false)
                    setRunStackerButtonText("Stacker Running")
                    setRunStackerButtonDisabled(false)
                break

                default:
                    setRunStackerButtonLoading(false)
                    setRunStackerButtonDisabled(false)
                    break
            }



        })
        .catch((error) => console.error(error))

    }, [token, customer_code]);

    const runStacker = (e) => {
        e.preventDefault()

        setRunStackerButtonLoading(true)
        setRunStackerButtonDisabled(true)
        setRunStackerButtonText('Running Stacker')

        // Swap the variable overrides into the form
        state['stacks'].map((stack, stackkey) => (
            stack['templates'].map((template, templatekey) => (
                template['variables'].map((variable, variablekey) => (
                    state['stacks'][stackkey]['templates'][templatekey]['variables'][variablekey]['value'] = state['variables'][template['name']][variable['name']]
                ))
            ))
        ))

        fetch(`${config.api_endpoint}/stacker/run`, {
            mode: "cors",
            method: "POST",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                customers: [
                    customer
                ]
            }),
        })
        .then((response) => response.json())
        .then((json) => {
            console.log(json)
        })
        .catch((error) => console.error(error));


    };

    const getStatusIndicator = (status) => {
        switch (status) {
          case "Pending":
            return "warning";
          case "Queued":
          case "Updating":
            return "in-progress";
          case "Completed":
            return "success";
          default:
            return "error";
        }
      };

    return (
        <SpaceBetween size='l'>

        {customer &&

            <Container header={
                <Header variant='h1'>
                    {customer['customer_name']}
                </Header>
            }>

                <ColumnLayout columns={6} variant='text-grid' minColumnWidth='100'>
                    <ValueWithLabel label='AWS Account ID'>
                        {customer["aws_account_id"]}
                    </ValueWithLabel>

                    <ValueWithLabel label='Customer Code'>
                        {customer['customer_code']}
                    </ValueWithLabel>

                    <ValueWithLabel label='Last Modified By'>
                        {customer['modified_by']}
                    </ValueWithLabel>

                    <ValueWithLabel label='Last Modified On'>
                        {formatTimestamp(customer['modified_on'])}
                    </ValueWithLabel>

                    <ValueWithLabel label='Stacker Status'>
                        <StatusIndicator type={getStatusIndicator(customer["stacker_status"])}>
                            {customer["stacker_status"]}
                        </StatusIndicator>
                    </ValueWithLabel>

                    <Button onClick={runStacker} loading={runStackerButtonLoading} disabled={runStackerButtonDisabled}>{runStackerButtonText}</Button>

                </ColumnLayout>

                <hr />

                <StackerConfigDisplay customer={customer} setCustomer={setCustomer} state={state} setState={setState}/>



            </Container>

        }
        </SpaceBetween>
    );
};

const mapStateToProps = (state) => ({
    token: state.token.accessToken,
});

export default connect(mapStateToProps)(Customer);
